import { inject, Injectable } from "@angular/core";
import { Network } from "@capacitor/network";
import { ToastController } from "@ionic/angular";
import { BehaviorSubject, Observable } from "rxjs";

export enum ConnectionStatus {
  Online,
  Offline,
}

@Injectable({
  providedIn: "root",
})
export class NetworkService {
  private toastController = inject(ToastController);

  public status$: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(
    navigator.onLine ? ConnectionStatus.Online : ConnectionStatus.Offline
  );

  constructor() {
    Network.addListener("networkStatusChange", (status) => {
      this.status$.next(
        status.connected ? ConnectionStatus.Online : ConnectionStatus.Offline
      );
    });

    // Initial check using Capacitor Network Plugin
    Network.getStatus().then((status) => {
      this.status$.next(
        status.connected ? ConnectionStatus.Online : ConnectionStatus.Offline
      );
    });

    // Fallback for browsers
    window.addEventListener("online", () =>
      this.status$.next(ConnectionStatus.Online)
    );
    window.addEventListener("offline", () =>
      this.status$.next(ConnectionStatus.Offline)
    );
  }

  public async updateNetworkStatus(status: ConnectionStatus) {
    this.status$.next(status);

    let connection = status == ConnectionStatus.Offline ? "Offline" : "Online";
    let toast = this.toastController.create({
      message: `You are now ${connection}`,
      duration: 3000,
      position: "bottom",
    });
    toast.then((toast) => toast.present());
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status$.asObservable();
  }

  public getStatus() {
    return this.status$.getValue();
  }

  public isOnline(): boolean {
    return this.status$.getValue() == ConnectionStatus.Online;
  }

  public isOffline(): boolean {
    return this.status$.getValue() == ConnectionStatus.Offline;
  }
}
