import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, of, retry, switchMap, tap, timer } from "rxjs";
import { AuthService } from "./auth.service";
import { ConnectionStatus, NetworkService } from "./network.service";
import { OfflineQueueApiActions } from "../state";

@Injectable({
  providedIn: "root",
})
export class OfflineQueueService {
  private store = inject(Store);
  private authService = inject(AuthService);
  private networkService = inject(NetworkService);

  constructor() {
    this.onNetworkChange();
  }

  private onNetworkChange(): void {
    this.networkService
      .onNetworkChange()
      .pipe(
        switchMap((status) => {
          if (status === ConnectionStatus.Online) {
            return this.authService.ping().pipe(
              retry({ delay: (error, retryCount) => timer(10000) }),
              tap(() => {
                this.store.dispatch(
                  OfflineQueueApiActions.processOfflineQueue()
                );
              }),
              catchError((err) => {
                console.error("Ping failed after retries", err);
                return of(status);
              })
            );
          }
          return of(status);
        })
      )
      .subscribe();
  }
}

// import { Injectable } from "@angular/core";
// import { Store } from "@ngrx/store";
// import { NetworkService, ConnectionStatus } from "./network.service";
// import { AuthService } from "./auth.service";
// import { SynchronizerApiActions } from "./synchronizer-api.actions";
// import { Observable, of, timer } from "rxjs";
// import { switchMap, tap, retry, catchError } from "rxjs/operators";

// @Injectable({
//   providedIn: "root",
// })
// export class SyncService {
//   constructor(
//     private networkService: NetworkService,
//     private authService: AuthService,
//     private store: Store
//   ) {
//     this.subscribeToNetworkChanges();
//   }

//   private isNetworkThrottled(): boolean {
//     const connection =
//       navigator.connection ||
//       navigator.mozConnection ||
//       navigator.webkitConnection;
//     if (connection) {
//       // Check for slow network conditions
//       const effectiveType = connection.effectiveType;
//       const rtt = connection.rtt;
//       const downlink = connection.downlink;

//       // Consider network throttled if effective type is slow or RTT is high or downlink is low
//       return (
//         ["2g", "slow-2g"].includes(effectiveType) ||
//         rtt > 1000 ||
//         downlink < 0.5
//       );
//     }
//     return false;
//   }

//   private subscribeToNetworkChanges(): void {
//     this.networkService
//       .onNetworkChange()
//       .pipe(
//         switchMap((status) => {
//           if (status === ConnectionStatus.Online) {
//             if (this.isNetworkThrottled()) {
//               console.warn("Network is throttled, skipping synchronization.");
//               return of(status);
//             }

//             return this.authService.ping().pipe(
//               retry({ delay: (error, retryCount) => timer(10000) }),
//               tap(() => {
//                 this.store.dispatch(
//                   SynchronizerApiActions.processSynchronizer()
//                 );
//               }),
//               catchError((err) => {
//                 console.error("Ping failed after retries", err);
//                 return of(status);
//               })
//             );
//           }
//           return of(status);
//         })
//       )
//       .subscribe();
//   }
// }
