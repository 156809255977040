import { inject, Injectable } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ApiId, ATTACHMENT_THUMBNAIL } from "@app/models";
import {
  FileOpener,
  FileOpenerOptions,
} from "@capacitor-community/file-opener";
import {
  Directory,
  Encoding,
  Filesystem,
  GetUriResult,
  ReadFileResult,
  StatResult,
  WriteFileOptions,
  WriteFileResult,
} from "@capacitor/filesystem";
import { fromByteArray, toByteArray } from "base64-js";
import { defer, from, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

export const FILESYSTEM_DIR = Directory.Data;

export const THUMBNAIL_WIDTH = 150;

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private sanitizer = inject(DomSanitizer);

  constructor() {}

  public writeFile(
    path: string,
    fileId: ApiId,
    data: any,
    encoding?: Encoding
  ): Observable<WriteFileResult> {
    const writeOptions: WriteFileOptions = {
      directory: FILESYSTEM_DIR,
      path: `${path}/${fileId}`,
      data: data,
      recursive: true,
    };
    if (encoding) {
      writeOptions.encoding = encoding;
    }
    return from(Filesystem.writeFile(writeOptions));
  }

  public readFile(path: string, fileId: ApiId): Observable<string> {
    return from(
      Filesystem.readFile({
        directory: FILESYSTEM_DIR,
        path: `${path}/${fileId}`,
      })
    ).pipe(map((result: ReadFileResult) => result.data as string));
  }

  public deleteFile(path: string, fileId: ApiId): Observable<void> {
    return from(
      Filesystem.deleteFile({
        directory: FILESYSTEM_DIR,
        path: `${path}/${fileId}`,
      })
    );
  }

  public getUri(path: string, fileId: ApiId): Observable<GetUriResult> {
    return from(
      Filesystem.getUri({
        directory: FILESYSTEM_DIR,
        path: `${path}/${fileId}`,
      })
    );
  }

  public getStats(path: string, fileId: ApiId): Observable<string> {
    return from(
      Filesystem.stat({
        directory: FILESYSTEM_DIR,
        path: `${path}/${fileId}`,
      })
    ).pipe(map((result: StatResult) => result.uri));
  }

  public openFile(
    path: string,
    mimeType: string,
    openWithDefault: boolean = true
  ): Observable<void> {
    const fileOpenerOptions: FileOpenerOptions = {
      filePath: path,
      contentType: mimeType,
      openWithDefault,
    };
    return from(FileOpener.open(fileOpenerOptions));
  }

  public blobToBase64(blob: Blob): Observable<string> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        try {
          const arrayBuffer = reader.result as ArrayBuffer;
          const byteArray = new Uint8Array(arrayBuffer);
          const base64String = fromByteArray(byteArray);
          observer.next(base64String);
          observer.complete();
        } catch (error) {
          observer.error(error);
        }
      };

      reader.onerror = (error) => {
        observer.error(error);
      };

      reader.readAsArrayBuffer(blob);
    });
  }

  public base64ToBlob(base64: string, contentType: string): Blob {
    const byteArray = toByteArray(base64);
    return new Blob([byteArray], { type: contentType });
  }

  private loadImageFromBlob(blob: Blob): Observable<HTMLImageElement> {
    return defer(() => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        const url = URL.createObjectURL(blob);

        img.onload = () => {
          URL.revokeObjectURL(url);
          resolve(img);
        };

        img.onerror = (error) => {
          URL.revokeObjectURL(url);
          reject(error);
        };

        img.src = url;
      });
    }).pipe(from);
  }

  private loadVideoFromBlob(blob: Blob): Observable<HTMLVideoElement> {
    return defer(() => {
      return new Promise<HTMLVideoElement>((resolve, reject) => {
        const video = document.createElement("video");
        const url = URL.createObjectURL(blob);

        video.onloadeddata = () => {
          URL.revokeObjectURL(url);
          resolve(video);
        };

        video.onerror = (error) => {
          URL.revokeObjectURL(url);
          reject(error);
        };

        video.src = url;
        video.muted = true;
        video.currentTime = 1;
        video.load();
      });
    });
  }

  private getImageThumbnail(img: HTMLImageElement): SafeUrl {
    const canvas = document.createElement("canvas");
    const aspectRatio = img.width / img.height;

    canvas.width = THUMBNAIL_WIDTH;
    canvas.height = THUMBNAIL_WIDTH / aspectRatio;

    const context = canvas.getContext("2d");
    context?.drawImage(img, 0, 0, canvas.width, canvas.height);

    const dataUrl = canvas.toDataURL("image/jpeg");
    return this.sanitizer.bypassSecurityTrustUrl(dataUrl);
  }

  private getVideoThumbnail(video: HTMLVideoElement): SafeUrl {
    const canvas = document.createElement("canvas");
    const aspectRatio = video.videoWidth / video.videoHeight;

    canvas.width = THUMBNAIL_WIDTH;
    canvas.height = THUMBNAIL_WIDTH / aspectRatio;

    const context = canvas.getContext("2d");
    context?.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataUrl = canvas.toDataURL("image/jpeg");
    return this.sanitizer.bypassSecurityTrustUrl(dataUrl);
  }

  public getThumbnail(blob: Blob): Observable<SafeUrl> {
    if (blob.type.includes("image/")) {
      return this.loadImageFromBlob(blob).pipe(
        map((img) => this.getImageThumbnail(img))
      );
    } else if (blob.type.includes("video/")) {
      return this.loadVideoFromBlob(blob).pipe(
        map((video) => this.getVideoThumbnail(video))
      );
    } else {
      return of(ATTACHMENT_THUMBNAIL);
    }
  }

  public downloadBase64(
    fileData: string,
    fileName: string,
    mimeType: string
  ): void {
    const blob = this.base64ToBlob(fileData, mimeType);
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(blobUrl);
  }
}
