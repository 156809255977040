import { Component, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonTitle,
  IonToolbar,
} from "@ionic/angular/standalone";

@Component({
  selector: "app-relogin-modal",
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Login</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()">Cancel</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item>
        <ion-input
          [(ngModel)]="username"
          label="Username"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-input
          [(ngModel)]="password"
          label="Password"
          labelPlacement="floating"
          type="password"
        ></ion-input>
      </ion-item>
      <ion-button expand="block" (click)="submit()">Login</ion-button>
    </ion-content>
  `,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonItem,
    IonInput,
    FormsModule,
  ],
  providers: [ModalController],
})
export class ReLoginModalComponent {
  private modalCtrl = inject(ModalController);

  username: string;
  password: string;

  constructor() {}

  dismiss() {
    this.modalCtrl.dismiss(null);
  }

  submit() {
    this.modalCtrl.dismiss({
      username: this.username,
      password: this.password,
    });
  }
}
