import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ReLoginModalComponent } from "@app/components/auth/auth.modal";
import { AuthTokens, AuthUser, Company } from "@app/models";
import { ModalController } from "@ionic/angular/standalone";
import { BehaviorSubject, from, Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ErrorService } from "./error.service";
import { NetworkService } from "./network.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private http = inject(HttpClient);
  private router = inject(Router);
  private errorService = inject(ErrorService);
  private networkService = inject(NetworkService);
  private modalController = inject(ModalController);

  private readonly ACCESS_TOKEN = "ACCESS_TOKEN";
  private readonly REFRESH_TOKEN = "REFRESH_TOKEN";
  private readonly LOGGED_USER = "LOGGED_USER";
  private readonly COMPANY = "COMPANY";

  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor() {}

  public init() {
    if (this.networkService.isOnline()) {
      return this.ping().pipe(
        tap(() => this.loggedIn.next(true)),
        catchError(() => {
          this.loggedIn.next(false);
          return of(true);
        })
      );
    } else {
      this.loggedIn.next(true);
      return of(true);
    }
  }

  public ping() {
    return this.http.get(`${environment.apiUrl}/auth/valid`, {
      observe: "response",
    });
  }

  public login(user: {
    username: string;
    password: string;
  }): Observable<boolean> {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/get_tokens`, null, {
        params: user,
      })
      .pipe(
        tap((auth) =>
          this.storeTokens({
            accessToken: auth.access_token,
            refreshToken: auth.refresh_token,
          })
        ),
        switchMap((auth) =>
          this.http.get<any>(`${environment.apiUrl}/res.users/${auth.uid}`, {
            params: {
              exclude_fields: JSON.stringify(["*"]),
              include_fields: JSON.stringify([
                "id",
                "login",
                "name",
                "email",
                "dopa_role",
                "company_id",
                ["company_ids", [["id", "name"]]],
              ]),
            },
          })
        ),
        tap((user: AuthUser) => this.storeLoggedUser(user)),
        tap(() => this.loggedIn.next(true)),
        map(() => true),
        catchError((error) => this.errorService.handleError(error))
      );
  }

  public logout() {
    this.removeTokens();
    this.loggedIn.next(false);
    this.router.navigate(["/", "auth"]);
  }

  public showReLoginModal(username: string = ""): Observable<{
    username: string;
    password: string;
  } | null> {
    return from(
      this.modalController
        .create({
          component: ReLoginModalComponent,
          componentProps: { username },
        })
        .then(async (modal) => {
          await modal.present();
          const { data } = await modal.onWillDismiss();
          return data;
        })
    );
  }

  public promptReLogin(): Observable<string> {
    const loggedUser = this.getLoggedUser();
    return this.showReLoginModal(loggedUser.login).pipe(
      switchMap((credentials) => {
        if (credentials) {
          return this.login(credentials).pipe(map(() => this.getAccessToken()));
        } else {
          return throwError(() => new Error("Re-login cancelled"));
        }
      })
    );
  }

  public isLoggedIn() {
    return this.loggedIn.getValue();
  }

  public refreshToken() {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/refresh_token`, {
        refresh_token: this.getRefreshToken(),
      })
      .pipe(
        tap((response: any) => {
          this.storeAccessToken(response.access_token);
        })
      );
  }

  public getAccessToken(): string {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  public getLoggedUser(): AuthUser {
    const loggedUser = localStorage.getItem(this.LOGGED_USER);
    if (!loggedUser) {
      return new AuthUser();
    }
    try {
      return new AuthUser(JSON.parse(loggedUser));
    } catch (error) {
      return new AuthUser();
    }
  }

  private getRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeAccessToken(accessToken: string): void {
    localStorage.setItem(this.ACCESS_TOKEN, accessToken);
  }

  private storeTokens(tokens: AuthTokens): void {
    localStorage.setItem(this.ACCESS_TOKEN, tokens.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private storeLoggedUser(user: AuthUser): void {
    localStorage.setItem(this.LOGGED_USER, JSON.stringify(user));
    const companyId = this.getCompany();
    if (
      !companyId ||
      !user.company_ids.map(({ id }) => id).includes(companyId)
    ) {
      const newCompanyId = user.company_id.toString();
      this.storeCompany(newCompanyId);
    }
  }

  private removeTokens(): void {
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

  public getCompanies(): Company[] {
    const user = this.getLoggedUser();
    return user.company_ids.map((company) => new Company(company));
  }

  public getCompany(): number {
    const company = localStorage.getItem(this.COMPANY);
    return company ? parseInt(company) : null;
  }

  public storeCompany(companyId: string): void {
    localStorage.setItem(this.COMPANY, companyId);
  }
}
