import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { Observable, throwError, TimeoutError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ErrorService implements ErrorHandler {
  handleError(error: any): Observable<never> {
    if (error instanceof HttpErrorResponse) {
      switch (error.error.error) {
        case "odoo_user_authentication_failed": {
          return throwError(() => "User authentication failed!");
        }
        default: {
          return throwError(() => error.error.error_descrip);
        }
      }
    }
    if (error instanceof TimeoutError) {
      return throwError(() => error.message);
    }
    return throwError(() => error);
  }
}
